<script>


/**
 * Commission Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";
import AgentAutoComplete from '@/components/agent-auto-complete-input'

import { financial } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        //flatPickr
        AgentAutoComplete,
    },

    validations: {
       
        agent : {
            name: { required },
            origin_commission: { required },
            origin_gst: { required }
        }
    },



    data() {
        return {
            agent :  {
                init_data               : {style_class : {'form-control' : 'form-control'}},
                name                    : '',
                origin_commission       : 0,
                orgin_gst               : 0,
                origin_total_commission : 0,
            },
            agents: [],
            deleted_agents: [],
            choice_obj: [],
            el_list: [],
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.$emit('confirm', this.agent)
            }
        },



        onSelectedAgent(selected_agent, evt) {
            let agent = this.agents[evt.$attrs['agent_idx']]
            agent.vin = selected_agent.agent.code
            agent.first_name = selected_agent.agent.first_name
            agent.last_name = selected_agent.agent.last_name
            agent.phone = selected_agent.agent.phone
            agent.brokerage = 'Remax Master'
            agent.email = selected_agent.agent.email
            agent.agent_vin = agent.vin
            agent.name = selected_agent.agent.first_name + ' ' + selected_agent.agent.last_name + ' (' + selected_agent.agent.code + ')'
            agent.init_data = { agent_name: agent.name, agent_id: '' }

            agent.is_new    = true

        },


        watch_commission(evt) {
            if (Number.isNaN(evt.target.value)) {
                return
            }
            this.agent.origin_gst = financial(Number(evt.target.value) * 0.05, 2)
            this.agent.origin_total_commission = financial(Number(this.agent.origin_commission) + Number(this.agent.origin_gst), 2)
            if (!this.agent.is_new && this.agent.id) {
                this.agent.is_edit = true
            }
        },


        watch_commission_gst(evt) {
          
            if (Number.isNaN(evt.target.value)) {
                return
            }
            this.agent.origin_total_commission = financial(Number(this.agent.origin_commission) + Number(this.agent.origin_gst), 2)
            if (!this.agent.is_new && this.agent.id) {
                this.agent.is_edit = true
            }
        },


    },

    created() {

    },

    mounted() {
        this.agent.name = this.deal.agents[0].full_name
        this.agent.origin_commission         = this.deal.ledger_preview.total_commission
        this.agent.origin_gst                = this.deal.ledger_preview.total_gst
        this.agent.origin_total_commission   = this.agent.origin_commission + this.agent.origin_gst 

      
        console.log(this.deal)
        this.$refs['agent_name_ref'].update_input_agent_name(this.agent.name)
    }

};
</script>

<template>
    <div class="row">

        <form class="needs-validation" @submit.prevent="formSubmit">


            <div class="row mb-3">

                <div class="col-sm-4">
                    <label class="form-label">Agent</label>
                    <AgentAutoComplete ref="agent_name_ref" :agent_idx="0" v-model="agent.name" :init_data="agent.init_data"
                        @onSelected="onSelectedAgent" autocomplete="off"
                        :class="{ 'is-invalid': $v.agent.name.$error }" />
                    <div v-if="$v.agent.name.$error" class="invalid-feedback">
                        <span v-if="$v.agent.name.$error">This value is required.</span>
                    </div>
                </div>

                <div class="col-sm-2">
                    <label class="form-label">Commission</label>
                    <input type="text" class="form-control" v-model="agent.origin_commission"
                        :class="{ 'is-invalid': $v.agent.origin_commission.$error }"
                        @input="watch_commission($event, 0)" @blur="watch_commission($event, 0)" />
                    <div v-if="$v.agent.origin_commission.$error" class="invalid-feedback">
                        <span v-if="$v.agent.origin_commission.$error">This value is required.</span>
                    </div>
                </div>

                <div class="col-sm-2">
                    <label class="form-label">GST</label>
                    <input type="text" class="form-control" v-model="agent.origin_gst"
                        :class="{ 'is-invalid': $v.agent.origin_gst.$error }"
                        @input="watch_commission_gst($event, 0)" @blur="watch_commission_gst($event, 0)" />
                    <div v-if="$v.agent.origin_gst.$error" class="invalid-feedback">
                        <span v-if="$v.agent.origin_gst.$error">This value is required.</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label class="form-label">Total</label>
                    <input type="text" class="form-control" v-model="agent.origin_total_commission" readonly />
                </div>

            </div>


            <div class="row mt-3">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                    <div class="d-flex flex-wrap gap-2">
                        <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                        <b-button variant="primary" type="submit">Submit</b-button>
                    </div>
                </div>
                <div class="col-md-4">

                </div>

            </div>
            <!-- end row-->

        </form>

    </div>
</template>