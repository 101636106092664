<script>


/**
 * Cheque Print  component
 */


import {
    required,
} from "vuelidate/lib/validators";


import {getCashApi} from '@/api/cash'
import simplebar from "simplebar-vue";
import { financial } from '@/api/misc'
import moment from 'moment'
export default {

    emits: [ 'close', 'reprint','reverse'],

    props: ['transaction'],
    components: {
        simplebar,
        
    },

    validations: {
        cheque_id : {required},
    },



    data() {

        return {
            submitted: false,
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            bank_account   : '',
            total_amount   : 0,
            post_date      : '',
            desc           : '',
            type           : '',
            detail_items   : [],
            reprint_flag   : true,
            enabled_reverse: true
            
        }

    },
    methods: {
        f(v, d = 2) {
            return financial(v, d)
        },

        get_post_date(d) {
            return moment(d).format("YYYY-MM-DD")
        },

        onReprint() {
           this.$emit('reprint')
        },

         

        onReverse() {
           this.$emit('reverse')
        },


    },

    created() {


    },

    mounted() {

        let data = {
            transaction_id : this.transaction.id
        }
        getCashApi().query_transaction_detail(data).then(res => {
            if (res.errCode == 0) {
                this.bank_account = res.data.bank_account
                this.type         = res.data.type
                this.desc         = res.data.remarks
                this.total_amount = res.data.amount
                this.post_date    = res.data.transaction_time
                this.detail_items = res.data.details
                this.enabled_reverse  = res.data.is_reversed == 1 ?false : true
               
            }
        })

    },

   

};
</script>

<template>
    
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <input class="form-control" :value="bank_account" name="choices-bank-account"  readonly />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Amount</label>
                            <input class="form-control" :value="total_amount" name="choices-bank-account"  readonly />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Post Date</label>
                            <input class="form-control" :value="get_post_date(post_date)" name="choices-bank-account"  readonly />
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="choices-bank-account" class="form-label">Description</label>
                        <input type="text" class="form-control"  :value="desc" readonly/>
                    </div>
                </div>

               

                <simplebar class="table-container"  data-simplebar-auto-hide="false" >
                <div class="table-responsive" style="max-height: 400px;">
                    <div class="table align-middle table-nowrap" style="height:100%" >
                        <b-table :items="detail_items" :fields="['Amount','Remark']" responsive="sm" :per-page="detail_items.length" 
                        class="table-check">
                           
                            <template #cell(Amount)="data">
                                {{ data.item.amount }}
                            </template>
                            <template #cell(Remark)="data">
                                {{ data.item.remarks }}
                            </template>
                        </b-table>
                    </div>
                </div>
            </simplebar>

                <div class="row mt-3">
                    <div class="col-md-3">

                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('close')">Close</b-button>
                            <b-button variant="primary" type="button" @click="onReprint" v-if="type == 'CHEQUE'">Re-Print</b-button>
                            <b-button variant="primary" type="button" @click="onReverse" v-if="(type == 'CHEQUE' || type == 'EFT') && enabled_reverse == true">Reverse</b-button>
                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>