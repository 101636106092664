<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Choices from "choices.js";
import moment from 'moment'
import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'
import { getBrokerageApi } from '@/api/brokerage'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {
    pm_month : {required},
    transfer_date: { required },
    selected_amount: { required },
    selected_pm: { required },
  },


  methods: {

    formSubmit() {

      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Sumbit?",
        () => {

          this.$v.$touch();
          if (this.$v.$invalid == false) {
            let data = {
              post_rent_type: this.d_type,
              properties    : this.listingData,
              post_date     : this.transfer_date
            }
            getPMApi().batch_deposit_rent_etransfer(data).then(res => {
              if (res.errCode == 0) {
                this.$alertify.message("Property Rent deposit");
                this.$router.push({ name: 'pm-property-list' })
              } else {
                this.$alertify.error(" Property  Rent deposit failed:" + res.errCode);
              }
            })
          }
        })

    },

    pm_list(property_id) {
      let data = {
        payment_type: 'ETransfer'
      }
      if (property_id) {
        data.property_id = property_id
      }
      getPMApi().query_pm_list_by_payment_type(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          res.data.map(e => {
            this.listingData.push({
              code: e.property_id,
              pm_id: e.property_id,
              property_id: e.property_id,
              amount: e.monthly_rent,
              address: e.address,
            })
          })

          this.updateAccountAmount()
        }
      })
    },



    onPMSelected(evt) {
      this.selected_pm = evt.str_id
    },


    onFileUploaded(ev, resp) {

      if (resp[0].errCode == 200) {
        this.file = {
          save_route: resp[0].response.url,
          save_route_key: resp[0].response.key,
          file_name: resp[0].response.filename,
          size: resp[0].response.size,
          type: resp[0].response.type
        }
      }
    },


    onAddProperty() {
      this.$v.selected_amount.$touch()
      if (this.$v.selected_amount.$invalid == true) {
        return
      }

      this.$v.transfer_date.$touch()
      if (this.$v.transfer_date.$invalid == true) {
        return
      }
      this.$v.selected_pm.$touch()
      if (this.$v.selected_pm.$invalid == true) {
        return
      }

      if (this.txn) {
        let txn_code = this.listingData.find(e => e.txn == this.txn)
        if (txn_code) {
          this.$alertify.error("ETR Txn already exist, can not add same transaction");
          return
        }
      }

      let pm = this.searched_pm_data.find(e => e.property_id == this.selected_pm)

      let cache = this.listingData.find(e => e.pm_id == pm.property_id)

      if (!cache) {
        cache = { 
          code       : this.selected_pm,
          pm_id      : this.selected_pm,
          property_id: this.selected_pm,
          amount     : 0,
          address    : pm.address,
          post_date  : this.transfer_date,
          rent_month : this.pm_month,
          rent_items : [],
          txn        : this.txn,
        }
        this.listingData.push(cache)
      }
      cache.rent_items.push({ amount: this.selected_amount, type: this.selected_type , pm_month : this.pm_month, note : this.note, deposit_type : this.d_type})
      cache.amount += Number(this.selected_amount)

      this.updateAccountAmount()

      this.$alertify.message("Property Rent Item was Added to List");
    },

    removePMEntry(data) {
      this.listingData = this.listingData.filter(e => data.pm_id != e.pm_id)
      this.updateAccountAmount()
    },


    onSearchPM(str) {
      autoComplete({ types: ['PM'], str: str, selection: ['property_id', 'o_code', 'address'] }).then(res => {
        if (res.errCode == 0) {
          this.searched_pm_data = []
          res.data.map(e => {
            this.searched_pm_data.push(e)
          })
        }
      })
    },

    updateAccountAmount() {
      let pm_trust_bank_gl = {
        account: this.pm_trust_bank.gl_account,
        amount: 0
      }

      this.total_amount  =0
      this.accounting_list = []
      this.accounting_list.push(pm_trust_bank_gl)
      this.listingData.map(e => {
        pm_trust_bank_gl.amount += e.amount
        this.total_amount       += e.amount

        this.accounting_list.push({
          account: this.pm_trust_bank.gl_liability_account,
          pm_id: e.property_id,
          property_id: e.property_id,
          amount: -e.amount,
          address: e.address,
          rent_items: [{
            amount: e.monthly_rent,
            type: this.selected_type
          }]
        })

      })
    },

    removePMRentItem(pmItem, data) {
      let item = pmItem.rent_items[data.index]
      pmItem.amount -= item.amount
      pmItem.rent_items.splice(data.index, 1)
      if (pmItem.rent_items.length <= 0) {
        this.listingData = this.listingData.filter(e => e.pm_id != pmItem.pm_id)
      }

      this.updateAccountAmount()
    },
  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "PM List",
          href: "/pm/property_list",
        },
        {
          text: "Post PM Rent By ETransfer",
          active: true,
        },
      ],


      file: {},
      transfer_date  : '',
      pm_month       : '',
      d_type         : 'ETR',
      selected_pm    : '',
      selected_amount: '',
      selected_type  : 'Rent',
      note           : '',
      total_amount   : '',
      txn            : '',

      listingData: [],
      accounting_list: [],
      searched_pm_data: [],
      pm_trust_bank: {
        gl_account: 10100,
        gl_liability_account: 12106,
      },

      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 50,
      },
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    // vueDropzone: vue2Dropzone,
    VueBootstrapTypeahead,

  },

  created() {
    getBrokerageApi().configApi.pm_trust_bank_account().then(res => {
      this.pm_trust_bank = res.pm_trust
    })
  },

  mounted() {
   
    let today = moment()
   

    this.pm_month = today.format("YYYYMM")
   
    new Choices('#selected_type')
    new Choices('#d_type')

    if (this.$route.query.property_id) {
      this.selected_pm = new String(this.$route.query.property_id)
      this.$refs.property.inputValue = this.selected_pm
    }

   // this.pm_list()
    this.updateAccountAmount()
  },

  watch: {
    selected_pm(new_pm_str) {
      this.onSearchPM(new_pm_str)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-3">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h5 class="card-title">Deposit PM</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">



                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Type</label>
                        <select id="d_type" class="form-control" v-model="d_type">
                          <option value="ETR">E-Transfer</option>
                          <option value="WIRED">Wired</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >Total Amount</label>
                        <div class="mb-3">
                          <input type="text" class="form-control" readonly v-model="total_amount" />
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mt-4">
                        <div class="justify-content-right gap-3 d-flex flex-wrap">
                              <button type="submit" class="btn btn-primary w-sm" >
                              Submit Deposit
                            </button>
                            <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name : 'pm-property-list'})">
                              Cancel
                            </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card body-->
              </div>
              <!-- end card -->
            



              <div class="card border shadow-none mb-5 mt-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h5 class="card-title">Deposit Detail Review</h5>
                  </div>
                </div>
                <div class="card-body">

                  <div class="row">

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Transfer Date</label>
                        <div class="mb-3">

                          <flat-pickr v-model="transfer_date" placeholder="Select a date" class="form-control"
                            :class="{ 'is-invalid': $v.transfer_date.$error }"></flat-pickr>
                          <div v-if="$v.transfer_date.$error" class="invalid-feedback">
                            <span v-if="!$v.transfer_date.required">This value is required.</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">PM Month</label>
                        <flat-pickr v-model="pm_month" placeholder="Select a month" class="form-control"
                          :config="{ dateFormat: 'Ym', altFormat: 'Y-m', ariaDateFormat: 'Y-m' }"
                          :class="{ 'is-invalid': $v.pm_month.$error }"></flat-pickr>
                        <div v-if="$v.pm_month.$error" class="invalid-feedback">
                          <span v-if="!$v.pm_month.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Property</label>
                        <vue-bootstrap-typeahead ref="property" v-model="selected_pm" :value="selected_pm"
                          :data="searched_pm_data" :class="{'is-invalid': $v.selected_pm.$error}"
                          :serializer="s => s.str_id" :foramterDisplay="s => s.str_id" @hit="onPMSelected($event)"
                          autocomplete="off" />
                          <div v-if="$v.selected_pm.$error" class="invalid-feedback">
                            <span v-if="!$v.selected_pm.required">This value is required.</span>
                          </div>

                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Amount</label>

                        <input class="form-control" v-model="selected_amount"
                          :class="{ 'is-invalid': $v.selected_amount.$error }" />
                        <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                          <span v-if="!$v.selected_amount.required">This value is required.</span>
                        </div>


                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Type</label>
                        <select class="form-control" v-model="selected_type" id="selected_type">
                          <option value="Rent" selected>Rent</option>
                          <option value="Owner Reserved">Owner Reserved</option>
                          <option value="Secure Deposit">Secure Deposit</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>

                   

                    <div class="col-lg-2">
                      <div class="mt-4">
                        <b-button variant="primary" @click="onAddProperty">Add Entry</b-button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Note</label>
                        <input class="form-control" v-model="note" max-length="30" />
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Txn</label>
                        <input class="form-control" v-model="txn" max-length="40" />
                      </div>
                    </div>

                  </div>


                  <b-tabs class="mt-n5 pt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                    nav-wrapper-class="nav-tabs-custom">

                    <!--  Property Tab -->
                    <b-tab title="Property">
                      <div data-simplebar>
                        <div class="table-responsive mt-3">
                          <div class="table align-middle table-nowrap">
                            <b-table :items="listingData" :fields="['Code', 'Amount', 'Address', 'Remove']"
                              responsive="sm" :per-page="listingData.length" class="table-check">
                              <template #cell(Code)="data">
                                <feather :type="data.detailsShowing ? 'chevron-down' : 'chevron-right'"
                                  @click="data.toggleDetails"></feather>
                                {{ data.item.pm_id }}
                              </template>
                              <template #cell(Amount)="data">
                                ${{ data.item.amount.toLocaleString() }}
                              </template>
                              <template #cell(Address)="data">
                                {{ data.item.address }}
                              </template>
                              <template #cell(Remove)="data">
                                <div class="row icon-demo-content-10">
                                  <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMEntry(data.item)"><i
                                      class="uil-times font-size-4"></i></div>
                                </div>
                              </template>


                              <template v-slot:row-details="row">
                                <div class="table align-middle table-nowrap" style="margin-left:30px;">
                                  <b-table :items="row.item.rent_items"
                                    :fields="[{ key: 'Type' }, { key: 'Amount' }, { key: 'Action' }]" responsive="sm"
                                    :per-page="row.item.rent_items ? row.item.rent_items.length : 0"
                                    class="table-check col-md-10">
                                    <template #cell(Type)="data">
                                      {{ data.item.type }}
                                    </template>

                                    <template #cell(Amount)="data">
                                      ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                    <template #cell(Action)="data">
                                      <div class="row icon-demo-content-10">
                                        <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMRentItem(row.item, data)">
                                          <i class="uil-times font-size-4"></i>
                                        </div>
                                      </div>
                                    </template>

                                  </b-table>
                                </div>
                              </template>



                            </b-table>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <!--  Accounting Tab -->
                    <b-tab title="Accounting">
                      <div class="table-responsive mt-3">
                        <div class="table align-middle table-nowrap">
                          <b-table :items="accounting_list" :fields="['GL', 'Amount', 'Address']" responsive="sm"
                            :per-page="accounting_list.length" class="table-check">
                            <template #cell(GL)="data">
                              {{ data.item.account }}
                            </template>
                            <template #cell(Amount)="data">
                              ${{ data.item.amount.toLocaleString() }}
                            </template>

                            <template #cell(Address)="data">
                              {{ data.item.pm_id }} {{ data.item.address }}
                            </template>

                          </b-table>
                        </div>
                      </div>
                    </b-tab>

                  </b-tabs>

                </div>
                <!-- end card body-->
              </div>
              <!-- end card -->

            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
