

<script>

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { place_auto_complete,postal_code_query } from '@/api/misc'

export default {


    emits: ['onSelected', 'onPostalCodeUpdate'],

    props: {
        init_data: {
            type: Object,
            default: () => ({
                address: '',
                style_class:  {'form-control' : 'form-control'},
            })
        },

        fill_full : {
            type: Boolean,
            default: () => true,
        },

        query_postal_code:  {
            type: Boolean,
            default: () => false,
        }
    },

    data() {
        return {
            address_choice: {},
            address_items: [],
            addressSearch: '',
        }

    },

    methods: {
        onSearched(new_value) {
            place_auto_complete({ str: new_value }).then(res => {
                this.address_items = []
                res.data.map((d) => {
                    this.address_items.push(d)
                })

                if (this.address_items.length <= 0) {
                    this.address_items.push ({
                        full       : new_value,
                        city       : '',
                        province   : '',
                        street     : new_value,
                        postal_code: '',
                        reference   : '',
                    })
                }
            })
        },

        onHit(evt) {
            if (this.fill_full) {
                this.$refs['native_typeahead'].inputValue = evt.full
                this.$emit('input', evt.full)
            } else {
                this.$refs['native_typeahead'].inputValue = evt.street
                this.$emit('input', evt.street)
            }
            this.$emit('onSelected', evt, this)

            if (this.query_postal_code) {
                postal_code_query({str : evt.reference}).then(res => {
                    if (res.errCode == 0) {
                        evt.postal_code = res.data
                        this.$emit('onPostalCodeUpdate', evt, this)
                    }
                })
            }
           
        },

        setValue(val) {
            this.$refs['native_typeahead'].inputValue =val
        }
    },

    created() {
        
    },

    mounted() {
        if (this.init_data.address) {
            this.$refs['native_typeahead'].inputValue = this.init_data.address
        }
    },

    components: {
        VueBootstrapTypeahead
    },

    computed: {
        address_value() {
            return this.init_data.address
        }
    },

    watch: {
        addressSearch: function (addr) {this.onSearched(addr) },

        // init_data : function(new_p) {
        //     if (new_p)
        //         this.$refs['native_typeahead'].inputValue = new_p.address
        // }
    },



}
</script>

<template>
    <vue-bootstrap-typeahead v-model="addressSearch" :data="address_items"
    :styleClass="init_data.style_class"  ref="native_typeahead"
         :serializer="s => s.full" :foramterDisplay="s => s.full" :remoteMatch="true" @hit="onHit($event)"
        autocomplete="off" />
</template>