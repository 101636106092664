<script>

import appConfig from "@/app.config";


import {getPMApi} from '@/api/pm'

/**
 * Read-email component
 */
export default {
  page: {
    title: "Read Email",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  emits:['close'],

  props : {
    mail_item : {
      type : Object,
      default: ()=> { }
    }
  },
  components: {

  },
  data() {
    return {

      download_url : '#'
    };
  },

  
  created() {
    

  },

  mounted() {
    let data = {
          pm_id      : this.mail_item.property_id,
          start_date : this.mail_item.start_date,
          end_date   : this.mail_item.end_date,
          enable_description : true
      }

    getPMApi().download_pm_statement(data).then(res => {
          if (res.errCode == 0) {
            this.download_url = res.url
          }

    })
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <!-- Left sidebar -->


      <!-- Right Sidebar -->
      <div class="mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-start mb-4">
              <div class="flex-shrink-0 me-3">

              </div>

              <div class="flex-grow-1">
                <h5 class="font-size-14 my-1"> {{mail_item.property_id}} Statement Mail Preview</h5>
                <small class="text-muted">To: {{mail_item.email}}  {{mail_item.email2?mail_item.email2 : ''}}  {{mail_item.email3?mail_item.email3 : ''}}  </small>
              </div>
            </div>


            <p>Dear Owner,</p>

            <p>Please find the {{ mail_item.start_date }} -{{ mail_item.end_date }} (if the content
              can only be edited by programmer,
              then maybe not mention the period but just the statement ) statement attached.
              If you have any questions pertaining to your statement, please contact your Property Manager directly.
            </p>

            <p></p>
            <p> Please do not reply to this email – this mailbox is not monitored.</p>
            <p> Warm regards,</p>
            <p> RE/MAX Masters Realty</p>


            <hr />

            <div class="row">
              <div class="col-xl-2 col-6">
                <div class="card">
              
                  <div class="py-2 text-center">
                    <a :href="download_url" target="_blank" class="fw-medium" >Attachment Download</a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <!-- card -->

      <div class="row">
        <div class="mb-3">
          <div class="col-auto d-flex flex-wrap justify-content-center">
            <b-button variant="primary" class="primary" @click="$emit('close')">Close</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- end Col-9 -->
  </div>
</template>
