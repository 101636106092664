<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {
  required,
} from "vuelidate/lib/validators";

import appConfig from "@/app.config";
import { getDealBasicApi, } from '@/api/deal/basic'
import { getDealApi, } from '@/api/deal'


import { place_auto_complete } from '@/api/misc'



export default {
  page: {
    title: "Deal Contacts Edit",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "Deal Contacts Edit",
      items: [
        {
          text: "Deal",
        },
        {
          text: this.$route.query.deal_id,
          href: '/deal/deal_overview?deal_id=' + this.$route.query.deal_id
        },
        {
          text: "Contacts Edit",
          active: true,
        },
      ],

      address_items: [],
      addressSearch: '',


      contacts: [],
      del_contacts : [],

      type_options: [
        { value: 'Seller', text: 'Seller' },
        { value: 'Buyer', text: 'Buyer' },
        { value: 'Seller Lawyer', text: 'Seller Lawyer' },
        { value: 'Buyer Lawyer', text: 'Buyer Lawyer' },
      ]

    };
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead

  },





  validations: {
    contacts: {
      $each: {
        name: { required },
        first_name: { required },
        last_name: { required },

      }
    }

  },

  watch: {
    addressSearch: function (addr) { this.onSearched(addr) }
  },


  methods: {
    formSubmit() {


      if (this.$v.$error == false) {
        getDealBasicApi().edit_contacts( this.$route.query.deal_id, this.contacts,  this.del_contacts).then(res => {
          if (res.errCode == 0) {
            console.log("====")
          } else {
            this.$alertify.error("Edit Failed ret:" + res.errCode + '  ' + res.msg);
          }
        })
      }
    },

    onSearched(e) {
      place_auto_complete({ "str": e }).then((res) => {
        this.address_items = []

        res.data.map((d) => {
          this.address_items.push(d)
        })

      })
    },



    onHit(e) {

      this.seller.city_name = e.city.trim()
      this.seller.province_name = e.province.trim()
      this.seller.seller_country = e.country
      this.seller.seller_postal = e.postal_code
      this.seller.seller_address = e.street

      this.addressSearch = e.street
      this.city_name = e.city.trim()
      this.province_name = e.province.trim()
      this.seller_postal = e.postal_code


    },



    onAddContact() {
      this.contacts.push({
        first_name: '',
        last_name: '',
        name: '',

        street: '',
        unit: '',
        city: '',
        province: '',
        postal_code: '',
        country: '',

      })
    },

    onContactRemove(idx) {
      let c = this.contacts[idx]
      this.contacts.splice(idx, 1)
      
      if (c.id) {
        this.del_contacts.push(c)
      }
    }

  },




  created() {

    getDealApi().detail({ order_deal_no: this.$route.query.deal_id }).then((res) => {
      if (res.errCode == 0) {
        this.deal = res.data
        this.contacts.push(...this.deal.buyers, ...this.deal.sellers, ...this.deal.lawyers)
      }

    })

  },

  mounted() {

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />




    <div class="card border shadow-none mb-2">

      <div class="card-header">
        <div class="col-auto">
          <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">


            <!-- <b-button  variant="info" v-b-modal.modal-send-report-dialog>Send Report</b-button> -->

            <b-button variant="info" @click="onAddContact">Add New Contact</b-button>
          </div>
        </div>

      </div>
      <div class="card-body">

        <form class="needs-validation" @submit.prevent="formSubmit">


          <div class="card border shadow-none mb-2" v-for="(c, idx) in contacts" :key="'contact_' + idx">
            <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-sm">
                  <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                    {{ idx + 1 }}
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="card-title">Contact</h5>
              </div>
            </div>
            <div class="card-body">

              <div class="row">

                <div class="row">

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom01">Type</label>
                      <b-form-select class="form-control" v-model="c.type" :options="type_options">

                      </b-form-select>
                    </div>
                  </div>


                  <div class="col-md-3">
                    <div class="mb-3">
                      <label for="validationCustom01">First name</label>
                      <input id="validationCustom01" v-model="c.first_name" type="text" class="form-control"
                        placeholder="First name" :class="{
                          'is-invalid': $v.contacts.$each[idx].first_name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3">
                      <label for="validationCustom02">Last name</label>
                      <input id="validationCustom02" v-model="c.last_name" type="text" class="form-control"
                        placeholder="Last name" :class="{
                          'is-invalid': $v.contacts.$each[idx].first_name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom03">Phone</label>
                      <input id="validationCustom03" v-model="c.phone" type="text" class="form-control"
                        placeholder="Phone" />
                    </div>
                  </div>
                  <!-- end col-->
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom04">Email</label>
                      <input id="validationCustom04" v-model="c.email" type="text" class="form-control"
                        placeholder="Email" />

                    </div>
                  </div>
                  <!-- end col-->

                </div>
                <!-- end row-->

                <div class="row">
                  <div class="col-md-1">
                    <div class="mb-3">
                      <label for="validationCustom03">Unit</label>
                      <input id="validationCustom03" v-model="c.unit" type="text" class="form-control"
                        placeholder="Unit" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3">
                      <label for="validationCustom04">Address</label>
                      <vue-bootstrap-typeahead v-model="c.address" :data="address_items" :serializer="s => s.full"
                        :foramterDisplay="s => s.street" @hit="onHit($event)" autocomplete="off" />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="city_option" class="form-label font-size-13 text-muted">City</label>
                      <input class="form-control" data-trigger v-model="c.city" />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="province_option" class="form-label font-size-13 text-muted">Province</label>
                      <input class="form-control" data-trigger v-model="c.province" />

                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom05">Country</label>
                      <input id="validationCustom05" v-model="c.country" type="text" class="form-control"
                        placeholder="Country" />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom05">Postal Code</label>
                      <input id="validationCustom05" v-model="c.postal_code" type="text" class="form-control"
                        placeholder="Postal Code" />
                    </div>
                  </div>
                </div>
                <!-- end row-->



                <div class="row">

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom05">Occuption</label>
                      <input id="validationCustom05" v-model="c.itn" type="text" class="form-control"
                        placeholder="Occuption" />
                    </div>
                  </div>

                  <div class="col-md-1">
                    <div class="mb-3">
                      <label for="validationCustom05">DOB</label>
                      <input id="validationCustom05" v-model="c.itn" type="text" class="form-control"
                        placeholder="Birth" />
                    </div>
                  </div>


                  <div class="col-md-1">
                    <div class="mb-3">
                      <label for="validationCustom05">ITN</label>
                      <input id="validationCustom05" v-model="c.itn" type="text" class="form-control"
                        placeholder="ITN/SIN" />
                    </div>
                  </div>

                  <div class="col-md-1">
                    <h5 class="font-size-14 mb-3">Is Canada</h5>
                    <div class="d-flex flex-wrap gap-2">
                      <input type="checkbox" id="is_canada" switch="bool" v-model="c.is_non_resident" checked />
                      <label for="is_canada" data-on-label="Yes" data-off-label="No" class="mb-0"></label>
                    </div>
                  </div>

                  <div class="col-md-1">
                    <h5 class="font-size-14 mb-3">Higher Risk</h5>
                    <div class="d-flex flex-wrap gap-2">
                      <input type="checkbox" id="high_risk" switch="bool" v-model="c.higher_risk_flag" checked />
                      <label for="high_risk" data-on-label="Yes" data-off-label="No" class="mb-0"></label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <label for="validationCustom05">Reason of Higher Rish</label>
                      <input id="validationCustom05" v-model="c.reason" type="text" class="form-control"
                        placeholder="Birth" />
                    </div>
                  </div>


                  <div class="col-md-2">
                    <div class="row icon-demo-content-10 mb-3">
                      <label class="form-label" > {{ ' ' }}&nbsp;</label>
                      <div class="col-xl-3 col-lg-4 col-sm-6" @click="onContactRemove(idx)" >
                        <i class="uil-times font-size-4"></i>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- end row-->


              </div>
              <!-- end row loop-->

            </div>
            <!-- end card body-->
          </div>
          <!-- end card -->
          <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4">
              <div class="d-flex flex-wrap justify-content-center gap-2">
                <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                <b-button variant="primary" type="submit">Submit</b-button>
              </div>
            </div>
            <div class="col-md-4">

            </div>

          </div>
          <!-- end row-->

        </form>
      </div>
    </div>

  </Layout>
</template>
