<script>


/**
 * Deposit Edit component
 */



import {
    required,
} from "vuelidate/lib/validators";

import Choices from "choices.js";
import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import {postal_code_query} from '@/api/misc'

import { financial } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

    props: ['agent_list', 'referral'],
    components: {
        AddressAutoComplete,
        AgentAutoComplete
    },

    validations() {

        if (this.referral_type == 'Individual') {
            return {
                amount: { required },
                first_name: { required },
                last_name: { required },

            }
        } else if (this.referral_type == 'Agent') {
            return {
                amount: { required },
                agent_name: { required }
            }
        }

    },


    computed: {
       
    },


    data() {
        return {
            agent_id : '', 
            referral_type: 'Agent',
            amount: 0,
            gst   : 0,
            first_name: '',
            last_name: '',
            agent_name: '',
            agent_vin: '',
            brokerage : '',
            address: '',
            unit: '',
            city_name: '',
            province_name: '',
            country: '',
            postal_code: '',
            address_items: [],
            agent_items: [],
            office_name : ''
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            let obj = {
                agent_id           : this.agent_id,
                referral_type      : this.referral_type,
                amount             : this.amount,
                gst                : this.gst,
                
                first_name         : this.first_name,
                last_name          : this.last_name,

                
                unit               : this.unit,
                address            : this.address,
               
                agent_vin          : this.referral_type == 'Agent'?this.agent_vin   : '',
                brokerage          : this.referral_type == 'Agent'?this.brokerage   :'',
                office             : this.referral_type == 'Agent'?this.office_name :'',
            }


            if (this.referral && this.referral.id) {
                obj.referral_id = this.referral.id
            }
            if (this.$v.$error == false) {
                this.$emit('confirm', obj)
            }
        },

       

     
        onAgentSelected(e) {
            this.agent_vin   = e.agent.vin
            this.brokerage   = e.agent.brokerage_name
            this.office_name = e.agent.office_name
            this.address     = e.agent.address
            this.office_code = e.agent.office_code
            this.first_name  = e.agent.first_name
            this.last_name   = e.agent.last_name
            this.agent_name  =  e.agent.first_name +' '+e.agent.last_name
        },


        onHit(e) {

            this.address   = this.addressSearch +' '+ e.city.trim() + ' '+ e.province.trim() 

            if (!this.postal_code) {
                postal_code_query({ str: e.reference }).then(res => {
                    if (res.errCode == 0) {
                        this.address += res.data
                    }
                })
            }
        }





    },

    watch: {
        amount: function(new_val) {
            this.gst = financial(Number(new_val||0) * 0.05, 2)
        }
       
    },

    created() {


    },

    mounted() {
        let agents = [{
            label : "",
            value : "",
            agent : {}
        }]
        if (this.agent_list) {
            this.agent_list.map((a) => {
                agents.push({
                    label : `${a.first_name} ${a.last_name}`,
                    value : a.native_id,
                    selected : false,
                    agent : a
                })
            })
        }
       

        if (this.referral) {
            this.agent_id      = this.referral.agent_id
            this.referral_type = this.referral.referral_type
            this.amount        =  this.referral.amount
            this.gst           =  this.referral.gst
            this.first_name    =  this.referral.first_name
            this.last_name     =  this.referral.last_name
            this.agent_name    =  this.referral.first_name +' '+ this.referral.last_name
            this.agent_vin     =  this.referral.agent_vin
            this.brokerage     =  this.referral.brokerage
            this.address       =  this.referral.address
            this.unit          =  this.referral.unit
            this.city_name     =  this.referral.city_name
            this.province_name =  this.referral.province_name
            this.country       =  this.referral.country
            this.postal_code   =  this.referral.postal_code

            agents.map(e => e.selected  = e.value == this.referral.agent_id? true : false)

            this.$refs['agent'].update_input_agent_name(this.agent_name)
        }

        new Choices('#charge_agent_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
            choices: agents
        })

    }

};
</script>

<template>
    <div class="card">

        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="charge_agent_choice" class="form-label font-size-13 text-muted">Agent Name</label>
                            <select  class="form-control"  data-trigger
                                v-model="agent_id"
                                name="charge_agent_choice"
                                id="charge_agent_choice"
                            >
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row col-md-10">
                    <div class="col-md-4">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" :value="'Individual'"
                                v-model="referral_type" />
                            <label class="form-check-label" for="formRadios1">
                                UnLicenced Referral
                            </label>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="formRadios" :value="'Agent'"
                                v-model="referral_type" />
                            <label class="form-check-label">
                                Licenced Agent
                            </label>
                        </div>
                    </div>


                </div>
                <!-- end row-->


                <div class="row" v-if="referral_type == 'Individual'">

                    <div class="col-md-12 row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Amount</label>
                                <input type="text" class="form-control" id="formrow-email-input" v-model="amount" :class="{
                                    'is-invalid': $v.amount.$error,
                                }" />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.amount.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label"> GST</label>
                                <input type="text" class="form-control"  v-model="gst"  />
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <div class="col-md-12 row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" >First Name</label>
                                <input type="text" class="form-control" v-model="first_name" :class="{
                                    'is-invalid': $v.first_name.$error,
                                }" />
                                <div v-if="$v.first_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.first_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" >Last Name</label>
                                <input type="text" class="form-control" v-model="last_name" :class="{
                                    'is-invalid': $v.last_name.$error,
                                }" />
                                <div v-if="$v.last_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.last_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <div class="col-md-12 row">
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label class="form-label" for="formrow-email-input">Unit</label>
                                <input type="text" class="form-control" v-model="unit" />
                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-md-8">
                            <div class="mb-3">
                                <label class="form-label">Address</label>
                                <AddressAutoComplete v-model="address" />
                            </div>
                        </div>
                        <!-- end col -->

                       
                    </div>

                </div>
                <!-- end row-->





                <div class="row" v-if="referral_type == 'Agent'">

                    <div class="col-md-12 row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label" for="formrow-email-input">Amount</label>
                                <input type="text" class="form-control" v-model="amount" :class="{
                                    'is-invalid': $v.amount.$error,
                                }" />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.amount.required">This value is required.</span>
                                </div>

                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label"> GST</label>
                                <input type="text" class="form-control"  v-model="gst"  />
                            </div>
                        </div>
                        <!-- end col -->

                    </div>

                    <div class="col-md-12 row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="formrow-email-input">Agent Name</label>
                                <AgentAutoComplete  ref="agent" :init_data="{  agent_name : agent_name, style_class:{'form-control' : 'form-control', 'is-invalid': $v.agent_name.$error }}" :search_type="'REGBV'" @onSelected="onAgentSelected"
                                autocomplete="off" :class="{ 'is-invalid': true }" />

                            </div>
                            <div v-if="$v.agent_name.$error" class="invalid-feedback">
                                <span v-if="!$v.agent_name.required">This value is required.</span>
                            </div>
                        </div>
                        <!-- end col -->


                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" >Brokerage</label>
                                <input type="text" class="form-control" v-model="brokerage"/>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                </div>




                <div class="row mt-4  col-md-10">


                </div>
                <!-- end row-->


                <div class="row mt-4  col-md-10">

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>