<script>


/**
 * Seller component
 */

import {
    required,
} from "vuelidate/lib/validators";

//import Choices from "choices.js";

import { place_auto_complete } from '@/api/misc'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        VueBootstrapTypeahead
    },

    validations: {
        contacts: {
            $each: {
                name: { required },
                first_name: { required },
                last_name: { required },

            }
        }

    },

    watch: {
        addressSearch: function (addr) { this.onSearched(addr) }
    },

    data() {

        return {
            address_items: [],
            addressSearch: '',


            contacts  : []
        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$error == false) {
                this.seller.city_name = this.city_name
                this.seller.province_name = this.province_name
                this.seller.seller_postal = this.seller_postal
                this.$emit('confirm', this.seller)
            }
        },

        onSearched(e) {
            place_auto_complete({ "str": e }).then((res) => {
                this.address_items = []

                res.data.map((d) => {
                    this.address_items.push(d)
                })

            })
        },



        onHit(e) {

            this.seller.city_name = e.city.trim()
            this.seller.province_name = e.province.trim()
            this.seller.seller_country = e.country
            this.seller.seller_postal = e.postal_code
            this.seller.seller_address = e.street

            this.addressSearch = e.street
            this.city_name = e.city.trim()
            this.province_name = e.province.trim()
            this.seller_postal = e.postal_code


        }
    },


    created() {

    },

    mounted() {
        this.contacts = []
        this.contacts.push(...this.deal.buyers, ...this.deal.sellers, ...this.deal.lawyers)

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row" v-for="(c, idx) in contacts" :key="'contact_' + idx">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="validationCustom01">First name</label>
                                <input id="validationCustom01" v-model="c.first_name" type="text" class="form-control"
                                    placeholder="First name" :class="{
                                        'is-invalid': $v.contacts.$each[idx].first_name.$error,
                                    }" />
                                <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="validationCustom02">Last name</label>
                                <input id="validationCustom02" v-model="c.last_name" type="text" class="form-control"
                                    placeholder="Last name" :class="{
                                        'is-invalid': $v.contacts.$each[idx].first_name.$error,
                                    }" />
                                <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom03">Unit</label>
                                <input id="validationCustom03" v-model="c.unit" type="text" class="form-control"
                                    placeholder="Unit" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom04">Address</label>
                                <vue-bootstrap-typeahead v-model="c.address" :data="address_items"
                                    :serializer="s => s.full" :foramterDisplay="s => s.street" @hit="onHit($event)"
                                    autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="mb-3">
                                <label for="city_option" class="form-label font-size-13 text-muted">City</label>
                                <input class="form-control" data-trigger v-model="c.city" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="province_option" class="form-label font-size-13 text-muted">Province</label>
                                <input class="form-control" data-trigger v-model="c.province" />

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom05">Postal Code</label>
                                <input id="validationCustom05" v-model="c.postal_code" type="text" class="form-control"
                                    placeholder="Postal Code"  />
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom03">Phone</label>
                                <input id="validationCustom03" v-model="c.phone" type="text"
                                    class="form-control" placeholder="Phone" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom04">Email</label>
                                <input id="validationCustom04" v-model="c.email" type="text"
                                    class="form-control" placeholder="Email" />

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="validationCustom05">ITN</label>
                                <input id="validationCustom05" v-model="c.itn" type="text"
                                    class="form-control" placeholder="ITN/SIN" />
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-xl-6">
                            <h5 class="font-size-14 mb-3">Is Canada</h5>
                            <div class="d-flex flex-wrap gap-2">
                                <input type="checkbox" id="is_canada" switch="bool" v-model="c.is_non_resident"
                                    checked />
                                <label for="is_canada" data-on-label="Yes" data-off-label="No" class="mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                   
                </div>

                <div class="row">
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-4">
                            <div class="d-flex flex-wrap gap-2">
                                <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                                <b-button variant="primary" type="submit">Save</b-button>
                            </div>
                        </div>
                        <div class="col-md-4">

                        </div>

                    </div>
                    <!-- end row-->

            </form>
        </div>
    </div>
    <!-- end card -->
</template>