<script>


/**
 * Commission Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";
import AgentAutoComplete from '@/components/agent-auto-complete-input'

import { financial } from '@/api/misc'
import { getDealBasicApi } from '@/api/deal/basic'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        //flatPickr
        AgentAutoComplete,
    },

    validations: {
       
        sub_deal : {
            order_deal_id : {required},
            name          : { required },
            commission    : { required },
            gst           : { required }
        }
    },



    data() {
        return {
            sub_deal :  {
                init_data               : {style_class : {'form-control' : 'form-control'}},
                agent_id                : '',
                name                    : '',
                commission              : 0,
                gst                     : 0,
                total                   : 0,
                deal_type               : 'L',
                order_deal_id           : '',
            },
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.$emit('confirm', this.sub_deal)
            }
        },



        onSelectedAgent(selected_agent, evt) {
            evt;
            console.log(selected_agent)
            this.sub_deal.agent_id = selected_agent.agent.id
            this.sub_deal.name = `${selected_agent.agent.first_name} ${selected_agent.agent.last_name}`
        },


        watch_commission(evt) {
            if (Number.isNaN(evt.target.value)) {
                return
            }
            this.sub_deal.gst = financial(Number(evt.target.value) * 0.05, 2)
            this.sub_deal.total = financial(Number(this.sub_deal.commission) + Number(this.sub_deal.gst), 2)
        },


        watch_commission_gst(evt) {
          
            if (Number.isNaN(evt.target.value)) {
                return
            }
            this.sub_deal.total = financial(Number(this.sub_deal.commission) + Number(this.sub_deal.gst), 2)
        },


    },

    created() {

    },

    mounted() {
        getDealBasicApi().new_sub_deal_no(this.deal.order_deal_id).then(res => {
            if (res.errCode == 0) {
                this.sub_deal.order_deal_id = res.data
            }
        })
    }

};
</script>

<template>
    <div class="row">

        <form class="needs-validation" @submit.prevent="formSubmit">

            <div class="row mb-3">

                <div class="col-sm-4">
                    <label class="form-label">Sub Deal #</label>
                    <input type="text" class="form-control" v-model="sub_deal.order_deal_id"
                        :class="{ 'is-invalid': $v.sub_deal.order_deal_id.$error }" readonly/>
                    <div v-if="$v.sub_deal.order_deal_id.$error" class="invalid-feedback">
                        <span v-if="$v.sub_deal.order_deal_id.$error">This value is required.</span>
                    </div>
                </div>

                <div class="col-sm-2">
                    <label class="form-label">Side</label>
                    <b-form-select class="form-control" v-model="sub_deal.deal_type" :options="[{value : 'L', text : 'Listing'},{value : 'S', text : 'Selling'}]"></b-form-select>
                </div>
            </div>

            <div class="row mb-3">

                <div class="col-sm-4">
                    <label class="form-label">Agent</label>
                    <AgentAutoComplete ref="agent_name_ref" :agent_idx="0" v-model="sub_deal.name" :init_data="sub_deal.init_data"
                        @onSelected="onSelectedAgent" autocomplete="off"
                        :class="{ 'is-invalid': $v.sub_deal.name.$error }" />
                    <div v-if="$v.sub_deal.name.$error" class="invalid-feedback">
                        <span v-if="$v.sub_deal.name.$error">This value is required.</span>
                    </div>
                </div>

                <div class="col-sm-2">
                    <label class="form-label">Commission</label>
                    <input type="text" class="form-control" v-model="sub_deal.commission"
                        :class="{ 'is-invalid': $v.sub_deal.commission.$error }"
                        @input="watch_commission($event, 0)" @blur="watch_commission($event, 0)" />
                    <div v-if="$v.sub_deal.commission.$error" class="invalid-feedback">
                        <span v-if="$v.sub_deal.commission.$error">This value is required.</span>
                    </div>
                </div>

                <div class="col-sm-2">
                    <label class="form-label">GST</label>
                    <input type="text" class="form-control" v-model="sub_deal.gst"
                        :class="{ 'is-invalid': $v.sub_deal.gst.$error }"
                        @input="watch_commission_gst($event, 0)" @blur="watch_commission_gst($event, 0)" />
                    <div v-if="$v.sub_deal.gst.$error" class="invalid-feedback">
                        <span v-if="$v.sub_deal.gst.$error">This value is required.</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label class="form-label">Total</label>
                    <input type="text" class="form-control" v-model="sub_deal.total" readonly />
                </div>

            </div>


            <div class="row mt-3">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                    <div class="d-flex flex-wrap gap-2">
                        <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                        <b-button variant="primary" type="submit">Submit</b-button>
                    </div>
                </div>
                <div class="col-md-4">

                </div>

            </div>
            <!-- end row-->

        </form>

    </div>
</template>