<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";
import StmtUpdateDialog from './components/update_stmt_dialog.vue'
import AddReconciledDialog from './components/add_reconciled_record_dialog.vue'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import simplebar from "simplebar-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import {getBankReconciledApi} from '@/api/accounting/reconcile'
import {getCashApi} from '@/api/cash'
import { financial } from "@/api/misc";
import moment from 'moment'

/**
 * User list component
 */
export default {
  page: {
    title: "Reconciliation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      account_list: [],
      acount_choice_obj: {},
      current_account: {
        account_stmt : 0,
        account_balance : 0,
      },
      book_amount : 0,
      open_amount :0,
      miscell_amount : 0,
      outgoing_amount : 0,
      incoming_amount: 0,
      store_list : [],
      reconcile_date : moment(moment().format("YYYY-MM-") + moment().daysInMonth()).format('YYYY-MM-DD'),
      list_type: 'RECONCILE_LIST',
      title: "Bank",
      items: [
        {
          text: "Reconciliation",
          href: "/",
        },
      ],
      totalRows: 0,
      totalMsRows :0,
      currentPage: 1,
      perPage: 1000,
      msPerPage: 1000,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      is_finalizing : false,
      fields: [
        {
          key: "ID",
          sortable: true,
        },
        
        {
          key: "Date",
          sortable: true,
        },
        {
          key: "Ref",
          label : 'Ref',
          sortable: true,
        },
       
      
        {
          key: "Amount",
          sortable: true,
        },
        {
          key: "Description",
          sortable: false,
        },
        {
          key: "Rec",
          label : '',
          sortable: true,
        },
      ],

      ms_list : [],
      ms_fields: [
        {key : 'ID'},
        {key : 'update_date', label:'Date'},
        {key : 'Amount'},
        {key : 'remarks'},
        {key : 'Action'}
      ],

      current_misc_stmt : null,

      eft_list : [],
      ch_list  : [],
      reconciled_by : '',
      reconciled_by_number : '',
      searched_data: [],


      current_progress : 15,

      timer_id : '',

      statement_list : [],

      total_bs_file_count    : 0,
      total_bs_file_amount   : 0,
      matched_bs_file_count  : 0,
      matched_bs_file_amount : 0,
    };
  },
  components: {
    Layout,
    PageHeader,
    StmtUpdateDialog,
    VueBootstrapTypeahead,
    AddReconciledDialog,
    simplebar,
    vueDropzone: vue2Dropzone,
  },
  computed: {
   
    rows() {
      return this.totalRows
    },
    diff() {return this.current_account.account_balance + this.open_amount- this.miscell_amount  - this.current_account.account_stmt}
  },

  created() {
    
    getCashApi().bank_account_list({last_reconciled_date : 1}).then((res) =>{
      this.account_list.push({
          label: "",
          account: ""
        })
      if (res.errCode == 0) {
        res.data.accounts.map((a)=>{
          this.account_list.push({
            label       : a.name,
            value       : a.a_account,
            account     : a.a_account,
            account_obj : a,
            selected    : true
          })
          this.current_account = a
        })

        this.reconcile_date = this.current_account.next_reconciled_date
        this.acount_choice_obj.setChoices(this.account_list)
        this.queryList();
      }
    })
  },


  mounted() {

    this.acount_choice_obj= new Choices('#bank_account', {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
    })
  
  },
  methods: {

   

    queryList() {

      this.start_progress_dialog()
      let data = {
        'action'      : "RECONCILATION",
        "account"     : this.current_account.a_account,
        'r_stmt_date' : this.reconcile_date 
        
      }
      getBankReconciledApi().list(data).then((res)=>{
        this.list = []
        this.open_amount = 0
        this.miscell_amount = 0
        this.outgoing_amount = 0
        this.incoming_amount = 0
        this.eft_list = []
        this.ch_list  = []
        res.data.map((o) =>{
        
          let obj =  {
            id           : o.id,
            bank_name    : o.bank_name,
            date         :  moment(o.update_date).format("YYYY-MM-DD"),
            mdate        :  moment(o.update_date),
            ref_id       : o.ref_id,
            description  : o.remarks,
            amount       :o.amount, 
            flag         : o.flag,
            op_flag      : 0,
            origin_flag  : o.flag,
            tag_txn      : o.tag_txn,
            _rowVariant  : o.flag == 1? '' : 'danger'
          }
          this.list.push(obj)
         
          if (o.flag == 1) {
            this.open_amount += o.amount
            if (o.amount > 0) {
              this.incoming_amount += o.amount
            } else {
              this.outgoing_amount += o.amount
            }
          }

          if (obj.ref_id.startsWith('EFT') || obj.ref_id.startsWith('PAD') || obj.ref_id.startsWith('ETR')) {
            this.eft_list.push(obj)
          } else  if (obj.ref_id.startsWith('CH')) {
            this.ch_list.push(obj)
          }
          
        })
        this.totalRows = res.page.total;

        
        this.ms_list = []
        res.miscellaneous_list.map((o) => {
          o.update_date = moment(o.update_date).format("YYYY-MM-DD")
          this.ms_list.push(o)
          this.miscell_amount += o.amount
        })
        this.totalMsRows = this.ms_list.length;

        this.stop_progress_dialog()
      })


    },


    changeAccount(evt) {
      let e  = this.account_list.find((e) => e.value == evt.detail.value)
      this.current_account =  e.account_obj
      this.reconcile_date = e.account_obj.next_reconciled_date
      this.queryList()
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },



    onReconcile(evt, rec) {
      let new_flag = evt.target.checked == true ?1 : 0
      this.doReoncile(new_flag, rec)
    },

    doReoncile(flag, rec) {
      let new_flag = flag
      //mark as operated, for clear case
      rec.op_flag = flag
      if (new_flag == 1) {
          this.open_amount += rec.amount
          if (rec.amount > 0) {
            this.incoming_amount += rec.amount
          } else {
            this.outgoing_amount += rec.amount
          }

      } else {
        this.open_amount -= rec.amount

        if (rec.amount > 0) {
          this.incoming_amount -= rec.amount
        } else {
          this.outgoing_amount -= rec.amount
        }
      }
      rec.flag = new_flag
      rec._rowVariant = rec.flag == 1? '' : 'danger'
      let cache  = this.store_list.find(e => e.stmt_id == rec.id)   
      if (!cache) {
        this.store_list.push({
          stmt_id : rec.id,
          new_flag : rec.flag
        })
      }

      
      if (rec.origin_flag == rec.flag) {
       
        this.store_list = this.store_list.filter(e => e.stmt_id != rec.id)   
        console.log(rec.origin_flag , rec.flag, rec)
      }
    },

    onClear() {
      this.store_list = []
      this.open_amount = 0
      this.incoming_amount = 0
      this.outgoing_amount = 0
      this.list.map((r) => {
        if (r.op_flag == 1) {
          r.op_flag = 0
          r.flag = r.origin_flag
        }
        if (r.flag == 1) {
            this.open_amount += r.amount

            if (r.amount > 0) {
              this.incoming_amount -= r.amount
            } else {
              this.outgoing_amount -= r.amount
            }
          }
      })
    },

    onStore() {

      let data = {
        stmt_ids : this.store_list
      }
      
      getBankReconciledApi().reconcile_store(data).then((res)=>{
          if (res.errCode == 0 ){
            this.$alertify.message("Store successfully  ");
            this.store_list = []
            this.queryList()
          } else {
            this.$alertify.error("Failed to Store " + res.errCode);
          }
      })
      

    },

    reconcil_preview() {
      getBankReconciledApi().preview_reconciled_statement({account : this.current_account.a_account}).then(res => {
        if (res.errCode == 0) {
            window.open(res.url, '_blank')
        } else {
          this.$alertify.error("Failed to Finalized " + res.errCode);
        }
      })
      
    },

    reconcil_finalize() {
      this.is_finalizing = true
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Finalize?",
        () => {

          getBankReconciledApi().finallized_reconciled({account : this.current_account.a_account}).then(res => {
            if (res.errCode == 0) {
              this.$alertify.message("Finalized successfully ");
              this.$router.push({name : 'br-review'})
            } else {
              this.$alertify.error("Failed to Finalized " + res.errCode);
            }
            this.is_finalizing = false
          })
        });
    },

    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Amount') {
          if (this.sortDesc) {
            return a.amount > b.amount ? -1 : 1
          } else {
            return a.amount > b.amount ? 1 : -1
          }
        } else if (this.sortBy == 'Date') {
          if (this.sortDesc) {
            return  -a.mdate.diff(b.mdate)
          } else {
            return a.mdate.diff(b.mdate)
          }
        } else if (this.sortBy == 'Trans') {
          if (this.sortDesc) {
            return a.trans_id > b.trans_id ? -1 : 1
          } else {
            return a.trans_id > b.trans_id ? 1 : -1
          }
        } else if (this.sortBy == 'Ref') {
          let number_a = a.ref_id
          let number_b = b.ref_id

          // if (number_a.startsWith('EFT') || number_a.startsWith('ETR')) {
          //   number_a = Number(number_a.substring(3, number_a.length))
          // } else {
          //   number_a = Number(number_a.substring(2, number_a.length))
          // }
          
          // if (number_b.startsWith('EFT') || number_b.startsWith('ETR')) {
          //   number_b = Number(number_b.substring(3, number_b.length))
          // } else {
          //   number_b = Number(number_b.substring(2, number_b.length))
          // }

          if (this.sortDesc) {
            return number_b > number_a ? 1 : -1
          } else {
            return  number_b > number_a ? -1 : 1 
          }
        } else if (this.sortBy == 'Rec') {
         
          if (a.flag == b.flag) {
            return  a.mdate.diff(b.mdate)
          } else {
            if (this.sortDesc) {
              return a.flag == 1 || a.flag == true ? 1 : -1
            } else {
              return b.flag == 1 || b.flag == true ? 1 : -1
            }
          }
        }


      })
    },

    get_description(item) {
      return item.description
    },


    get_flag_string(item) {
      if (item.flag == 1) {
        return 'Reconciled'
      } else if (item.flag == 2) {
        return 'Except'
      } else if (item.flag == 0) {
        return 'N/A'
      }
    },

    onStatmentUpdate(amount) {
      this.current_account.account_stmt = amount
      let data = {
        account_stmt : amount,
        account : this.current_account.a_account
      }
      getBankReconciledApi().update_account_stmt(data).then((res) => {
        if (res.errCode == 0) {
          this.$bvModal.hide('modal-statment-edit-dialog')
          this.$alertify.message("Update Success ");
        } else {
          this.$alertify.error("Update Failed "+ res.errCode);
        }
      })
    },

    onReconciledRecordSubmit(d) {
      let data = {
        amount : d.amount,
        remark : d.desc,
        date   : d.date,
        account : this.current_account.a_account,
        stmt_id : d.id,
      }

      if (data.stmt_id) {

        getBankReconciledApi().update_miscellaneous_record(data).then((res) => {
          if (res.errCode == 0) {
            let cache = this.ms_list.find(e=> e.id == data.stmt_id)
            this.miscell_amount -= Number(cache.amount)

            cache.amount       = data.amount
            cache.update_date  = data.date
            cache.remarks      = data.remark
            cache.description  = data.remark
           
            this.miscell_amount += Number(data.amount)

            this.$bvModal.hide('modal-recondiled-record-add-dialog')
            this.$alertify.message("Update Success ");
          } else {
            this.$alertify.error("Update Failed "+ res.errCode);
          }
        })

      } else {
        getBankReconciledApi().add_reconcil_miscellaneous_record(data).then((res) => {
          if (res.errCode == 0) {
            res.data.update_date = moment(res.data.update_date).format("YYYY-MM-DD")
            this.ms_list.push(res.data)
            this.totalMsRows = this.ms_list.length
            this.miscell_amount += Number(d.amount)
            this.$bvModal.hide('modal-recondiled-record-add-dialog')
            this.$alertify.message("Add Success ");

          } else {
            this.$alertify.error("Add Failed "+ res.errCode);
          }
        })
      }
     
     



    },

    getStatment() {
      return '$'+(this.current_account.account_stmt).toLocaleString()
    },

    getBankAccountBalance() {
      return (this.current_account.account_balance + this.open_amount- this.miscell_amount ).toLocaleString()
    },


    remove_miscell_record(item) {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Delete this Record?",
        () => {
          getBankReconciledApi().delete_stmt_record({stmt_id: item.id}).then(res=>{
            if (res.errCode == 0) {
              this.$alertify.message("Delete Success ");
              this.ms_list = this.ms_list.filter((e) =>e.id != item.id)
              this.totalMsRows = this.ms_list.length
              this.miscell_amount -= item.amount
            } else {
              this.$alertify.error("Delete Failed "+ res.errCode);
            }
          })
        })
    },

    edit_miscell_record(item) {
      this.current_misc_stmt = item
      this.$bvModal.show('modal-recondiled-record-add-dialog')
    },

    add_miscell_record() {
      this.current_misc_stmt = {}
      this.$bvModal.show('modal-recondiled-record-add-dialog')
    },

    showReconiledBy(re_type) {
      this.reconciled_by = re_type
      if (re_type =='CH') {
        this.searched_data = this.ch_list
        this.searched_data.map(e => e.origin_t_id = e.ref_id.substring(2))
      } else {
        this.searched_data = this.eft_list
        this.searched_data.map(e => e.origin_t_id = e.ref_id.substring(3))
      }
      this.$bvModal.show('modal-reonciled-by-dialog')
    },

    onStoreReonciledBy() {
      let reconciled_obj = this.list.find(e => e.ref_id == this.reconciled_by + this.reconciled_by_number)
      this.doReoncile(1, reconciled_obj)
    },


    start_progress_dialog() {
      this.current_progress = 10;
      clearInterval(this.timer_id)
      this.$bvModal.show('LoadingProgressDiloag')

     
      this.timer_id = setInterval(() => {
          
          if (this.current_progress < 90) {
            this.current_progress  += 10
          }
      }, 1500)
    },


    stop_progress_dialog() {
      this.$bvModal.hide('LoadingProgressDiloag')
      clearInterval(this.timer_id)
      this.current_progress = 10;
      
    },

    showStatementFileUploadDialog() {
      this.statement_list = []
      this.$bvModal.show('modal-bank-statment-file-matching-dialog')
    },

    onFileUploaded(file, resp) {
      file;

      this.statement_list = []
      if (resp.errCode == 0) {
        this.statement_list.push(...resp.data)

        this.statement_list.map(e => {
          e.update_flag = 0
          if (e.txn == '') {
             e._rowVariant  = 'danger'
            return
          }
          let m = this.list.find(c => c.tag_txn == e.txn)
          if (!m) {
              e._rowVariant  = 'danger'
          } else {
            e.matched         = 1
            e.description     = m.description
            e.matched_amount  = m.amount
            e.ref_id          = m.ref_id
            if (financial(e.amount, 2) != financial(m.amount, 2)) {
              e._rowVariant = 'warning'
            } else {
              m.sf_flag = 1
            }
          }
        })


        this.total_bs_file_count    = this.statement_list.length
        this.total_bs_file_amount   = this.statement_list.reduce((a, c) => a + Number(c.amount), 0)
        this.matched_bs_file_amount = this.statement_list.reduce((a, c) => a + Number(c.matched == 1? c.amount :0), 0)
        this.matched_bs_file_count  = this.statement_list.reduce((a, c) => a + (c.matched == 1? 1 :0), 0)
      }

     
    },

    onStatementFileConfirm() {
      
      
      this.list.map(e => {
        if (e.sf_flag == 1 && e.flag == 0) {
          this.doReoncile(1, e)
        }
      })

      this.$bvModal.hide('modal-bank-statment-file-matching-dialog')
    },

    onStatmentFileMatchAmount(evt) {
      
      let match_amount_flag = evt.target.checked?1 : 0

      this.list.map(e => {e.sf_flag = 0})
      
      this.statement_list.map(e => {
          if (e.txn == '') {
            e._rowVariant  = 'danger'
            return
          }
          e.update_flag = !e.update_flag
          let m = this.list.find(c => match_amount_flag == 1 ? (c.tag_txn == e.txn || financial(c.amount, 2) == financial(e.amount, 2)) : c.tag_txn == e.txn )
          if (!m) {
              e._rowVariant   = 'danger'
              e.matched       = 0
          } else {
            e.matched         = 1
            e.description     = m.description
            e.matched_amount  = m.amount
            e.ref_id          = m.ref_id
            if (financial(e.amount, 2) != financial(m.amount, 2)) {
              e._rowVariant = 'warning'
            } else {
              m.sf_flag = 1
              e._rowVariant = ''
            }
          }
        })

        this.total_bs_file_count    = this.statement_list.length
        this.total_bs_file_amount   = this.statement_list.reduce((a, c) => a + Number(c.amount), 0)
        this.matched_bs_file_amount = this.statement_list.reduce((a, c) => a + Number(c.matched == 1? c.amount :0), 0)
        this.matched_bs_file_count  = this.statement_list.reduce((a, c) => a + (c.matched == 1? 1 :0), 0)

        this.$refs['bs_file_table'].refresh()
    }
   
    
    
  },


  watch: {
    sortBy () {
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    },

    
  }


};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <h5 class="card-title">
                    Reconciliation
                    <span class="text-muted fw-normal ms-2">({{ this.list_type=='RECONCILE_LIST'? this.totalRows: this.totalMsRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-8">           
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >

                <label class="mt-2">Reconcile Date:</label>
                <div class="col-md-3">
                  <input class="form-control" :value="reconcile_date" readonly />
                </div>

               
                <label class="mt-2 ml-3">Account:</label>
                <div class="search-box col-md-4">
                    <div class="position-relative">
                      <select
                          class="form-control"
                          data-trigger
                          name="account"
                          id="bank_account"
                          @change="changeAccount"
                          >
                          <option value=" "></option>
                        </select>
                      
                    </div>
                  </div>


                  <b-dropdown
                    variant="white"
                    right
                    toggle-class="btn-link text-dark shadow-none"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                      
                    </template>
                    <b-dropdown-item @click="showStatementFileUploadDialog">Upload Bank Statement CSV</b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-recondiled-record-add-dialog>Add Miscellaneous Record</b-dropdown-item>
                  </b-dropdown>
                
                  
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <b-modal centered id="modal-statment-edit-dialog" title="Statement Update" size="lg" hide-footer>
              <StmtUpdateDialog :bank_account="current_account" @confirm="onStatmentUpdate" @cancel="$bvModal.hide('modal-statment-edit-dialog')"/>
            </b-modal>
            <!-- end dropdown -->

            <b-modal centered id="modal-recondiled-record-add-dialog" title="Miscellaneous Record" size="lg" hide-footer>
              <AddReconciledDialog :bank_account="current_account" :data="current_misc_stmt" @confirm="onReconciledRecordSubmit" @cancel="$bvModal.hide('modal-recondiled-record-add-dialog')"/>
            </b-modal>


            <b-modal centered id="modal-reonciled-by-dialog" :title="'Reconciled By ' + reconciled_by" size="lg" hide-footer>
              <div class="row">
                <div class="col-md-2">
                  <div class="justify-content-center mt-2 gap-3 d-flex flex-wrap"> {{ reconciled_by}}</div>
                 
                </div>
                <div class="col-md-3">
                  <vue-bootstrap-typeahead v-model="reconciled_by_number"  :data="searched_data"
                          :serializer="s => s.origin_t_id"
                          :foramterDisplay="s => s.origin_t_id"  autocomplete="off" />
                </div>

                <div class="col-md-2">
                  <b-button variant="primary" @click="onStoreReonciledBy">Store</b-button>
                </div>
              </div>
            </b-modal>


            <b-modal centerd id="LoadingProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
              <b-progress height="20px" :value="current_progress"></b-progress>
            </b-modal>

            <!-- File Matching Dialog-->
            <b-modal  id="modal-bank-statment-file-matching-dialog" title="Statement File Mathcing" size="xl" hide-footer>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <vue-dropzone id="expense-dropzone" :use-custom-slot="true"  
                      :options="{ url: '/adm/accounting/reconcile/upload_bank_statement', maxFilesize: 10, maxFiles: 1, paramName: 'statement_file', thumbnailHeight: 80, thumbnailWidth: 80, addRemoveLinks: true }"
                      @vdropzone-success="onFileUploaded">
                      <div>
                        <i class="display-4 text-muted "></i>
                        <h4>Drag  file to here.</h4>
                      </div>
                    </vue-dropzone>
                  </div>

                  <div class="row mt-3">

                    <div class="col-md-4">
                      <input type="checkbox"  @change="onStatmentFileMatchAmount($event)" />Match amount
                    </div>
                  </div>

                  <div class="row mt-3">

                    <div class="col-md-12">
                      <label class="form-control">Total Trans Count:{{ total_bs_file_count }},  Total Amount: {{ total_bs_file_amount.toLocaleString() }}, Matched Count : {{ matched_bs_file_count }}  Matched Amoount: {{ matched_bs_file_amount.toLocaleString() }}</label>
                    </div>
                  </div>

                  <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive mt-3" style="max-height: 800px;">
                      <div class="table align-middle table-nowrap">
                        <b-table
                          :items="statement_list"
                          :fields="[{key : 'SEQ' , thStyle: { width: '10%' } },{key : 'Amount', thStyle: { width: '20%' } },{key : 'Type', thStyle: { width: '15%' } }, {key : 'Txn', thStyle: { width: '10%' } }, {key : 'Matched', thStyle: { width: '20%' } }, {key : 'Ref', thStyle: { width: '15%' } }, {key : 'Description', thStyle: { width: '20%' } }]"
                          responsive="sm"
                          :per-page="statement_list.length"
                          class="datatables"
                          ref="bs_file_table"
                        >

                          <template  #cell(SEQ)="data">
                            {{ data.index + 1 }}
                          </template>
                        
                          <template #cell(Txn)="data"> 
                            {{ data.item.txn }}
                          </template>

                          <template #cell(Type)="data"> 
                            {{ data.item.txn_type }}
                          </template>
                          
                          <template #cell(Amount)="data">
                            {{ '$'+data.item.amount.toLocaleString()}}
                          </template>

                          <template #cell(Matched)="data">
                            {{ data.item.matched == 1? ('$'+data.item.matched_amount.toLocaleString()) : 'N/A'}}
                          </template>

                          <template #cell(Ref)="data">
                            {{  data.item.ref_id}}
                          </template>

                          <template #cell(Description)="data">
                            {{  get_description(data.item)}}
                          </template>



                        </b-table>
                      </div>
                    </div>
                    <!-- end table responsive -->
                  </simplebar>
                  <div class="row mt-3">
                      
                      <div class="col-md-12">
                          <div class="d-flex align-items-center justify-content-center  gap-2">
                              <b-button variant="secondary" type="button" @click="$bvModal.hide('modal-bank-statment-file-matching-dialog')">Cancel</b-button>
                              <b-button variant="primary"  type="button" @click="onStatementFileConfirm">Submit</b-button>
                          </div>
                      </div>

                  </div>
                  <!-- end row-->
                </div>
                <!-- end card body-->
              </div>
              <!-- end card-->
            </b-modal>



            <div class="row">
              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Book:${{ current_account.account_balance.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>+</label>
                </div>
              </div>

              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Open: ${{ open_amount.toLocaleString() }}</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>-</label>
                </div>
              </div>

              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Miscell: ${{ miscell_amount.toLocaleString() }}</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>=</label>
                </div>
              </div>


              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Bank Account: ${{ getBankAccountBalance()  }}</label>
                </div>
              </div>

            </div>
            <!-- end row -->
           

            <div class="row">
              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >Outgoing Cleared:</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap   justify-content-end">
                  <label >{{ outgoing_amount.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                <label>   Statment: {{ getStatment() }}</label>
                </div>
              </div>

            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >Incoming Cleared:</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >{{ incoming_amount.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                Diff:<label :class="diff != 0 ? 'text-danger' :''"> {{ '$'+diff.toLocaleString() }}</label>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="d-flex flex-wrap align-items-start">
                    <b-button class="btn-soft-info" :pressed="this.list_type=='RECONCILE_LIST'?true: false"  variant="info" @click="list_type='RECONCILE_LIST' ">
                      <i class="uil uil-info-circle me-2"></i> Reconcile
                    </b-button>
                    <b-button class="btn-soft-danger" :pressed="this.list_type=='MISCELLANEOUS_LIST'?true: false"   variant="danger" @click="list_type='MISCELLANEOUS_LIST'" >
                      <i class="uil uil-exclamation-octagon me-2"></i> Miscellaneous
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-end gap-2">


                  <b-dropdown  variant="secondary" >
                    <template slot="button-content">
                      Reconcile By
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item @click="showReconiledBy('EFT')">EFT</b-dropdown-item>
                    <b-dropdown-item  @click="showReconiledBy('CH')">Cheque</b-dropdown-item>
                  </b-dropdown>

                    <b-button  variant="primary" @click="reconcil_preview" >
                      <i class="uil uil-info-circle me-2"></i> Preview
                    </b-button>


                    <b-button  variant="danger" @click="reconcil_finalize" :disabled="is_finalizing" >
                      <i class="uil uil-info-circle me-2"></i> Finallize
                    </b-button>
                    <b-button  variant="info"  @click="add_miscell_record" >
                        <i class="uil uil-info-circle me-2"></i> Add Miscell
                      </b-button>
                  
                    <b-button  variant="purple"   v-b-modal.modal-statment-edit-dialog >
                        <i class="uil uil-info-circle me-2"></i> Update Statement
                    </b-button>

                   

                    <b-button  variant="secondary"  @click="onStore"  >
                        <i class="uil uil-info-circle me-2"></i> Store
                    </b-button>

                  </div>
              </div>

            </div>
            <!-- end row -->

            

            <div class="table-responsive" v-if="this.list_type=='RECONCILE_LIST'">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="list"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  class="datatables"
                >

                  <template  #cell(ID)="data">
                    {{ data.index + 1 }}
                  </template>
                 
                  <template #cell(Account)="data"> 
                    {{ data.item.bank_name }}
                  </template>

                  <template #cell(Date)="data">             
                      {{ data.item.date }}
                  </template>
                  <template #cell(Trans)="data">
                    {{  data.item.trans_id}}
                  </template>

                  <template #cell(Ref)="data">
                    {{  data.item.ref_id}}
                  </template>
                 
                  
                  <template #cell(Amount)="data">
                    {{ '$'+data.item.amount.toLocaleString()}}
                  </template>

                  <template #cell(Description)="data">
                    {{  get_description(data.item)}}
                  </template>

                  <template #cell(Rec)="data">
                    <input type="checkbox" :checked="data.item.flag == 1" @change="onReconcile($event, data.item)" />
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <!-- Miscellaneous Record Table-->
            <div class="table-responsive" v-if="this.list_type=='MISCELLANEOUS_LIST'">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="ms_list"
                  :fields="ms_fields"
                  responsive="sm"
                  :per-page="msPerPage"
                  class="table-check"
                >

                  <template  #cell(ID)="data">
                    {{ data.index + 1 }}
                  </template>
                 

                  <template #cell(Date)="data">             
                      {{ data.item.date }}
                  </template>

                  
                  <template #cell(Amount)="data">
                    {{ '$'+data.item.amount.toLocaleString()}}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown
                      variant="white"
                      right
                      toggle-class="btn-link text-dark shadow-none"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                    
                      <b-dropdown-item @click="edit_miscell_record(data.item)">Edit</b-dropdown-item>
                      <b-dropdown-item @click="remove_miscell_record(data.item)">Delete Misc. Stmt</b-dropdown-item>
                      </b-dropdown>

                    <!-- 
                    <b-button variant="outline-danger" size="sm" @click="remove_miscell_record(data.item)">
                      <i class="mdi mdi-close"></i>
                    </b-button>
                    -->
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
