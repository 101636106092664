
import request from '@/utils/request';



class CashApi {

    ap_list = (data) => {

        return request({
            url: '/adm/cash/ap/ap_list',
            method: 'post',
            data
        });	

    }


    bank_account_list =(data) => {
        return request({
            url: '/adm/config/bank/bank_account',
            method: 'post',
            data,
            
        });	
    }


    agent_credts= (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_credits',
            method: 'post',
            data
        });	
    }




    pay = (data) => {
        return request({
            url: '/adm/cash/print_cheque',
            method: 'post',
            data
        });	
    }

    transfer_records_list = (data) => {
        return request({
            url: '/adm/cash/banktransfer/transfer_record_list',
            method: 'post',
            data
        });	
    }


    make_transfer = (data) => {
        return request({
            url: '/adm/cash/banktransfer/make_transfer',
            method: 'post',
            data
        });	
    }

    make_manual_transfer = (data) => {
        return request({
            url: '/adm/cash/banktransfer/make_manual_transfer',
            method: 'post',
            data
        });	
    }

    make_eft_payment = (data) => {
        return request({
            url: '/adm/cash/banktransfer/make_eft_payment',
            method: 'post',
            data
        });	
    }

    make_tax_payment = (data) => {
        return request({
            url: '/adm/cash/banktransfer/make_tax_payment',
            method: 'post',
            data
        });	
    }


    eft_transaction_list = (data) => {
        return request({
            url: '/adm/cash/banktransfer/eft_transaction_list',
            method: 'post',
            data
        });	
    }

    eft_detail = (data) => {
        return request({
            url: '/adm/cash/banktransfer/eft_transaction_details',
            method: 'post',
            data
        });	
    }

    complete_eft_payment = (data) => {
        return request({
            url: '/adm/cash/banktransfer/complete_eft_payment',
            method: 'post',
            data
        });	
    }


    pad_transaction_list = (data) => {
        return request({
            url: '/adm/cash/banktransfer/pad_transaction_list',
            method: 'post',
            data
        });	
    }

    pad_detail = (data) => {
        return request({
            url: '/adm/cash/banktransfer/pad_transaction_details',
            method: 'post',
            data
        });	
    }


   

    complete_pad_charge = (data) => {
        return request({
            url: '/adm/cash/banktransfer/complete_pad_charge',
            method: 'post',
            data
        });	
    }


     make_pm_mgt_fee_transfer = (data) => {
        return request({
            url: '/adm/cash/banktransfer/make_pm_transfer',
            method: 'post',
            data
        });	
        
     }


     pm_mgt_fee_transfer_status_query = (data) => {
        return request({
            url: '/adm/cash/banktransfer/pm_transfer_job_query',
            method: 'post',
            data
        });	
        
     }




    agent_credit_setup = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_credit_setup',
            method: 'post',
            data
        });	
    }

    agent_credit_discard = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_credit_discard',
            method: 'post',
            data
        });	
    }


   
    create_regbv_expense= (data) => {
        return request({
            url: '/adm/cash/expense/create_regbv_expense',
            method: 'post',
            data
        });	
    }

    create_regbv_part2_expense= (data) => {
        return request({
            url: '/adm/cash/expense/create_regbv_part2_expense',
            method: 'post',
            data
        });	
    }

    


    create_western_expense= (data) => {
        return request({
            url: '/adm/cash/expense/create_western_expense',
            method: 'post',
            data
        });	
    }

    query_rebgv_agents =(data) => {
        return request({
            url: '/adm/cash/ap/query_board_agents_list',
            method: 'post',
            data
        });	
    }

    query_rebgv_unlicenced_agents =(data) => {
        return request({
            url: '/adm/cash/ap/query_board_unlicenced_agent_list',
            method: 'post',
            data
        });	
    }
 

    
    cancel_eft = (data) => {
        return request({
            url: '/adm/cash/banktransfer/cancel_eft',
            method: 'post',
            data
        });	
    }

    cancel_pad = (data) => {
        return request({
            url: '/adm/cash/banktransfer/cancel_pad',
            method: 'post',
            data
        });	
    }


    transaction_query = (data) => {
        return request({
            url: '/adm/cash/transaction/query',
            method: 'post',
            data
        });	
    }

    query_transaction_detail = (data) => {
        return request({
            url: '/adm/cash/transaction/detail',
            method: 'post',
            data
        });	
    }


    eft_slip = (data) => {
        return request({
            url: '/adm/cash/transaction/get_transaction_slip',
            method: 'post',
            data
        });	
    }


    reprint_transaction = (data) => {
        return request({
            url: '/adm/cash/transaction/reprint_transaction',
            method: 'post',
            data
        });	
    }



    reverse_transaction = (data) => {
        return request({
            url: '/adm/cash/transaction/reverse_transaction',
            method: 'post',
            data
        });	

    }




}



class ArApApi {

    agent_bill_query = (data) => {
        return request({
            url: '/adm/cash/ar/ar_agent_bill_list',
            method: 'post',
            data
        });	
    }

    charge_agent_fee =(data) => {
        return request({
            url: '/adm/cash/ar/charge_agent_fee',
            method: 'post',
            data
        });	
    }

    charge_agent_fee_immediately =(data) => {
        return request({
            url: '/adm/cash/ar/charge_agent_fee_immediately',
            method: 'post',
            data
        });	
    }


    deposit_cheque = (data) => {
        return request({
            url: '/adm/cash/deposit/deposit_cheque',
            method: 'post',
            data
        });	
    }

  

    deposit_agent_bill_cheque = (data) => {
        return request({
            url: '/adm/cash/ar/deposit_agent_bill_cheque',
            method: 'post',
            data
        });	
    }

    deposit_agent_bill_etransfer = (data) => {
        
        return request({
            url: '/adm/cash/ar/deposit_agent_bill_etransfer',
            method: 'post',
            data
        });	
    }



    print_transactions_cheque = (data) => {
        return request({
            url: '/adm/cash/ap/send_print_cheque_job',
            method: 'post',
            data
        });	
        
    }

    cancel_transaction = (data) => {
        return request({
            url: '/adm/cash/ap/cancel_payment',
            method: 'post',
            data
        });	
    }

    query_tax_summary = (data) => {
        return request({
            url: '/adm/cash/ap/tax_payment_query',
            method: 'post',
            data
        });	
    }


    setup_agent_bill_charge_file = (data) => {
        return request({
            url: '/adm/cash/ar/charge_agent_bill',
            method: 'post',
            data
        });	
    }

    query_regbv_agent_fee_list = (data) => {
        return request({
            url: '/adm/cash/expense/query_regbv_fee_record_list',
            method: 'post',
            data
        });	
    }

    query_western_agent_fee_list = (data) => {
        return request({
            url: '/adm/cash/expense/query_western_fee_record_list',
            method: 'post',
            data
        });	
    }




    create_recoverable_expense=(data) => {
        return request({
            url: '/adm/cash/expense/create_recoverable_expense',
            method: 'post',
            data
        });	
    }


    create_expense = (data) => {
        return request({
            url: '/adm/cash/expense/create_expense_payment',
            method: 'post',
            data
        });	
    }

    modify_expense = (data) => {
        return request({
            url: '/adm/cash/expense/edit_expense_payment',
            method: 'post',
            data
        });	
    }




    reverse_transaction = (data) => {
        return request({
            url: '/adm/cash/ap/reverse_transaction',
            method: 'post',
            data
        });	

    }


    reverse_agent_bill_transaction = (data) => {
        return request({
            url: '/adm/cash/ar/reverse_bill_transaction',
            method: 'post',
            data
        });	

    }


    query_supplier_expense_list = (data) => {
        return request({
            url: '/adm/cash/expense/query_supplier_expense_list',
            method: 'post',
            data
        });	
        
    }


    create_regbv_expense = (data) => {
        return request({
            url: '/adm/cash/expense/setup_regbv_expense',
            method: 'post',
            data
        });	
    }

    query_expense_detail= (data) => {
        return request({
            url: '/adm/cash/expense/expense_detail',
            method: 'post',
            data
        });	
    }



    upload_batch_charge_agents_form = (data) => {
        return request({
            url: '/adm/cash/ar/batch_upload_charged_form',
            method: 'post',
            data
        });	
    }

    submit_batch_form = (data) => {
        return request({
            url: '/adm/cash/ar/submit_batch_charge_agents',
            method: 'post',
            data
        });	
    }

    
    pay_agent_commission = (data) => {
        return request({
            url: '/adm/cash/ap/setup_agent_hold_commission_payment',
            method: 'post',
            data
        });	
        
    }


    approve_invoice_payment = (data) => {
        return request({
            url: '/adm/cash/ap/approve_payment',
            method: 'post',
            data
        });	
    }



    query_expense_by_invoice= (data) => {
        return request({
            url: '/adm/cash/expense/query_expense_by_invoice',
            method: 'post',
            data
        });	
    }


  


    edit_transaction_type = (data) => {
        return request({
            url: '/adm/cash/ap/edit_transaction_payment_type',
            method: 'post',
            data
        });	
        
    }


    cheque_preview = (data) => {
        return request({
            url: '/adm/cash/ap/cheque_print_preview',
            method: 'post',
            data
        });	
    }
}



class CashReportApi {

    export_pm_unpaid_list = (data) => {
        return request({
            url: '/adm/cash/report/export_pm_unpaid_transaction_list',
            method: 'post',
            data
        });	
    }
}


let _api = null

let _arapapi = null

let _cashreport = null


const getCashApi = () => {
    if (!_api) {
        _api = new CashApi();
    }
    return _api;
}

const getArApApi =() => {
    if (!_arapapi) {
        _arapapi = new ArApApi();
    }
    return _arapapi;
}

const getCashReportApi = () => {
    if (!_cashreport) {
        _cashreport = new CashReportApi();
    }
    return _cashreport;

    
}



export { getCashApi, getArApApi, getCashReportApi};


