<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'
import moment from 'moment'
import Choices from "choices.js";

import TransactionDetailDialog from "./components/transaction_detail_dialog.vue";
import ChequeReprintDialog from './components/cheque_reprint_dialog.vue'

/**
 * User list component
 */
export default {
  page: {
    title: "Bank Transaction",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Bank Transaction",
      items: [
        {
          text: "Bank Transaction",
          href: "/",
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,

      query_str : '',
      trans_type : '',
      fields: [

        {
          key: "Number",
          sortable: true,
           tdClass: 'align-middle'
        },
        {
          key: "Amount",
          sortable: true,
           tdClass: 'align-middle'
        },

        {
          key: "PostDate",
          sortable: true,
           tdClass: 'align-middle'
        },
        { key : 'Type',  tdClass: 'align-middle'},
        {
          key: "Description",
          sortable: true,
           tdClass: 'align-middle'
        },
        {key : 'Status',  tdClass: 'align-middle'},
        {key : 'Action',  tdClass: 'align-middle'}

      ],
     
      selected_transactions: [],
      selected_transaction : {}
    };
  },
  components: {
    Layout,
    PageHeader,
    TransactionDetailDialog,
    ChequeReprintDialog
    
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

  },

  created() {
     
  },
  mounted() {

    new Choices('#trans_type', {})
  },


  methods: {

    queryList() {

      let data = {
        query_str : this.query_str,
        trans_type : this.trans_type,
        page       : {page_size : this.perPage, page_no : this.currentPage}
      }

      getCashApi().transaction_query(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          this.list.push(o)
        })
        this.totalRows = res.page?(res.page.total || res.data.length):res.data.length

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    get_post_date(d) {
      return moment(d).format("YYYY-MM-DD")
    },

    onSearch() {
      this.currentPage = 1;
      this.queryList()
    },

    onShowDetail(t) {
      this.selected_transaction = t
      this.$bvModal.show('modal-transaction-detail-dialog');
    },

    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Amount') {
          if (this.sortDesc) {
            return a.amount > b.amount ? -1 : 1
          } else {
            return a.amount > b.amount ? 1 : -1
          }
        } 
      })
    },

    download_slip(item) {
      getCashApi().eft_slip({transaction_id : item.id}).then(res => {
        console.log(res)
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        }
      })
    },

    reverse_bank_trans(item) {
      console.log(item)
      let data = {
        trans_id     : this.selected_transaction.id,
        reverse_type : 'REVERSE'
      }
      getCashApi().reverse_transaction(data).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Reversed Succeed ");
        } else {
          this.$alertify.error("Reverse Trans Failed code:"+res.errCode);
        }
      })
    },

    onShowReprintDialog() {
      this.$bvModal.hide('modal-transaction-detail-dialog')
      this.$bvModal.show('modal-cheque-reprint-dialog')
    },

    onPrinted() {
      this.$bvModal.hide('modal-cheque-reprint-dialog')
    },

    onReverse() {
      this.$alertify.confirmWithTitle(
          'Reverse', 'Are you sure to Reverse this Transaction?',
          () => {
              this.reverse_bank_trans(this.selected_transaction)
              this.$bvModal.hide('modal-transaction-detail-dialog')
          },
          () => {
          }
      );
}

  },


  watch: {
    sortBy () {
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    },

  }

};
</script>

<template>
  <Layout>



    <b-modal centerd id="modal-cheque-reprint-dialog" :title="selected_transaction.transaction_code+' Reprint'" size="lg" hide-footer>
      <ChequeReprintDialog :transaction="selected_transaction"   @close="$bvModal.hide('modal-cheque-reprint-dialog')" />
    </b-modal>

    <b-modal centerd id="modal-transaction-detail-dialog" :title="selected_transaction.transaction_code" size="lg" hide-footer>
      <TransactionDetailDialog :transaction="selected_transaction"   @close="$bvModal.hide('modal-transaction-detail-dialog')"  @reprint="onShowReprintDialog"  @reverse="onReverse" />
    </b-modal>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">

              <div class="col-md-2">
                <select id="trans_type" class="form-control" v-model="trans_type">
                  <option value=""></option>
                  <option value="PAD">PAD</option>
                  <option value="EFT">EFT</option>
                  <option value="CHEQUE">Cheque</option>
                  <option value="TRANSFER">Transfer</option>
                </select>
              </div>

              <div class="col-md-4">
                <input class="form-control" v-model="query_str" v-on:keyup.enter="onSearch" placeholder="Search By Transaciton Number/Amount"/>
              </div>

              <div class="col-md-6 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">
                <b-button variant="info" @click="onSearch">Search</b-button>
              </div>

              </div>

          </div>
          <div class="card-body">
 


            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                  striped hover
                  class="table-check datatables">


                  <template #cell(Number)="data">
                    <b-link @click="onShowDetail(data.item)"> {{ data.item.transaction_code }}</b-link>
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PostDate)="data">
                    {{ get_post_date(data.item.transaction_time) }}
                  </template>

                  <template #cell(Description)="data">
                    {{ data.item.remarks }}
                  </template>

                  <template #cell(Type)="data">
                    {{ data.item.type }}
                  </template>

                  <template #cell(File)="data">
                    <b-link :href="data.item.eft_url">Download</b-link>
                  </template>
                  

                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown
                      variant="white"
                      right
                      toggle-class="btn-link text-dark shadow-none"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item :disabled="data.item.type != 'EFT'" @click="download_slip(data.item)">Slip Download</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.type != 'EFT' && data.item.is_reversed == '1'" @click="reverse_bank_trans(data.item)">Reverse</b-dropdown-item>
                     
                      </b-dropdown>

                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
